import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';
import creditAgricoleLogo from 'qonto/static/illustrations/accounts/credit-agricole.svg';
import revolutLogo from 'qonto/static/illustrations/accounts/revolut.svg';

export const getEmptyStateConfig = intl => {
  let dummyData = getDummyData(intl);

  let name = 'accounts';

  return {
    name,
    featureName: 'transactions',

    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        variations: [
          {
            output: {
              badgeType: BADGE_TYPE.ACTIVATE,
              badgeText: intl.t('empty-states.system.activate.a3.badge'),
              title: intl.t('accounts.empty-state.bankless.title'),
              subtitle: intl.t('accounts.empty-state.bankless.subtitle'),
              tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  let fourteenDaysAgo = dayjs().subtract(14, 'day').toISOString();

  return {
    items: [
      {
        id: 1,
        safeName: intl.t('accounts.empty-state.bankless.dummy-data.main-account'),
        bankName: intl.t('accounts.empty-state.bankless.dummy-data.bank-name'),
        name: intl.t('accounts.empty-state.bankless.dummy-data.bank-name'),
        logo: { medium: creditAgricoleLogo },
        authorizedBalance: intl.t('accounts.empty-state.bankless.dummy-data.balance-main'),
        identifier: intl.t('accounts.empty-state.bankless.dummy-data.bank-name-main'),
        lastSyncedAt: fourteenDaysAgo,
        isActive: true,
        balanceCurrency: 'EUR',
        isExternalAccount: true,
      },
      {
        id: 2,
        safeName: intl.t('accounts.empty-state.bankless.dummy-data.secondary-account'),
        bankName: intl.t('accounts.empty-state.bankless.dummy-data.bank-name'),
        name: intl.t('accounts.empty-state.bankless.dummy-data.bank-name'),
        logo: { medium: revolutLogo },
        authorizedBalance: intl.t('accounts.empty-state.bankless.dummy-data.balance-secondary'),
        identifier: intl.t('accounts.empty-state.bankless.dummy-data.bank-name-secondary'),
        lastSyncedAt: fourteenDaysAgo,
        isActive: true,
        balanceCurrency: 'EUR',
        isExternalAccount: true,
      },
    ],
  };
};
