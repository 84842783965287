import { CURRENCIES } from 'qonto/constants/international-out/currency';

export const DEFAULT_SOURCE_AMOUNT = '100.00';

export const DEFAULT_SOURCE_CURRENCY_CODE = CURRENCIES.EUR;
export const DEFAULT_TARGET_CURRENCY_CODE = CURRENCIES.USD;

export const ERROR_CODE = {
  ABOVE_MAXIMUM_AMOUNT: 'above_max_amount',
  BELOW_MINIMUM_AMOUNT: 'below_min_amount',
  NO_PAYMENT_OPTION_AVAILABLE: 'no_payment_option_available',
  TARGET_AMOUNT_VOLATILE: 'target_amount_volatile',
};

export const QUOTE_CREATION_STATUS = {
  SUCCESS: 'success',
  UNEXPECTED_ERROR: 'unexpected-error',
  VALIDATION_ERROR: 'validation-error',
};

export const STATUSES = {
  PENDING: 'PENDING',
};
