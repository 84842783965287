export const DEFAULT_CHECKS_CURRENCY = 'EUR';

export const MAX_CHECK_DEPOSIT_AMOUNT = 15000;
export const MAX_CHECK_DEPOSIT_AMOUNT_ON_PERIOD = 30000;

export const STATUS = {
  PENDING: 'pending',
  VALIDATED: 'validated',
  DECLINED: 'declined',
  REFUNDED: 'refunded',
  RECEIVED: 'received',
  CANCELED: 'canceled',
} as const;

export type CheckStatus = (typeof STATUS)[keyof typeof STATUS];

export const CHECK_COMPLETED_STATUSES: CheckStatus[] = [STATUS.VALIDATED, STATUS.RECEIVED];
