export const CERTIFICATION_DURATION_DAYS = 42;
export const LOW_MEDIUM_RISK_DUE_DILIGENCE_WEEKS = 8;
export const HIGH_RISK_DUE_DILIGENCE_WEEKS = 4;
export const QONTO_VERIFICATION_URL = 'https://verification.qonto.com/kyc';
export const COMPLETE_QONTO_VERIFICATION_URL_REGEX = new RegExp(
  'https://verification\\.qonto\\.com/kyc/[0-9]+\\?locale=[\\w-]+&qonto_redirection_url=.+'
);

export const COMPANY_REPORT_STATUS = {
  NOT_FOUND: 'not_found',
  FOUND: 'found',
  UNKNOWN: 'unknown',
};

export const IS_PERIODIC_WARNING_REVIEWED = 'isPeriodicWarningReviewed';

export const RISK_CLASS = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  BANNED: 'banned',
} as const;

export type RiskClass = (typeof RISK_CLASS)[keyof typeof RISK_CLASS];

export const TYPEFORM_LINKS: Record<string, string> = {
  association_high_it: 'https://qonto.typeform.com/to/RH2DokZi',
  association_high_en: 'https://qonto.typeform.com/to/pQnXwn1q',
  freelancer_low_it: 'https://qonto.typeform.com/to/l53DKSAG',
  freelancer_low_en: 'https://qonto.typeform.com/to/whkCDN2e',
  freelancer_medium_it: 'https://qonto.typeform.com/to/l53DKSAG',
  freelancer_medium_en: 'https://qonto.typeform.com/to/whkCDN2e',
  freelancer_high_it: 'https://qonto.typeform.com/to/brGc4wYQ',
  freelancer_high_en: 'https://qonto.typeform.com/to/QGDJ8tic',
  share_capital_company_low_it: 'https://qonto.typeform.com/to/MC6x2dfn',
  share_capital_company_low_en: 'https://qonto.typeform.com/to/ZP9l0fjT',
  share_capital_company_medium_it: 'https://qonto.typeform.com/to/MC6x2dfn',
  share_capital_company_medium_en: 'https://qonto.typeform.com/to/ZP9l0fjT',
  share_capital_company_high_it: 'https://qonto.typeform.com/to/Kp0IB1My',
  share_capital_company_high_en: 'https://qonto.typeform.com/to/xUAIJpoM',
};
