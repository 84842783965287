export const RIBA_ROUTES = {
  PROCESSING: 'riba.processing',
  COMPLETED: 'riba.completed',
  TASKS: 'riba.tasks',
};

export const RIBA_TABS = {
  TASKS: 'TASKS',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
};

export const RIBA_STATUS = {
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
  DECLINED: 'declined',
} as const;

export const IS_RIBA_LOGIN_PROMOTION = 'login-feature-passive-riba';
