import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';

const name = 'products';
const featureName = 'receivableInvoices';

export const getEmptyStateConfig = (intl, { ctaCallback, secondaryCtaCallback }) => {
  let dummyData = getDummyData(intl);

  return {
    name,
    featureName,
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        onClick: ctaCallback,
        tracking: getTrackingNameAndProperties({
          name: 'catalog_add-new_clicked',
          type: TYPES.ACTIVATE,
        }),
        variations: [
          {
            output: {
              badgeType: BADGE_TYPE.ACTIVATE,
              badgeText: intl.t('empty-states.system.activate.a3.badge'),
              title: intl.t('products.list.empty-state.a3.title'),
              subtitle: intl.t('products.list.empty-state.a3.subtitle'),
              ctaText: intl.t('products.list.empty-state.a3.cta.primary'),
              secondaryCtaText: intl.t('products.list.cta.tertiary'),
              onSecondaryCtaClick: secondaryCtaCallback,
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  return {
    columnsToShow: {
      name: true,
      email: true,
    },
    items: [
      {
        title: intl.t('empty-states.system.dummy-data.product-title-1'),
        internalNote: intl.t('empty-states.system.dummy-data.product-internal-note-1'),
        formattedUnit: intl.t('empty-states.system.dummy-data.product-unit-1'),
        unitPrice: {
          value: intl.t('empty-states.system.dummy-data.product-price-1').split(' ')[0],
          currency: intl.t('empty-states.system.dummy-data.product-price-1').split(' ')[1],
        },
      },
      {
        title: intl.t('empty-states.system.dummy-data.product-title-2'),
        internalNote: intl.t('empty-states.system.dummy-data.product-internal-note-2'),
        formattedUnit: intl.t('empty-states.system.dummy-data.product-unit-2'),
        unitPrice: {
          value: intl.t('empty-states.system.dummy-data.product-price-2').split(' ')[0],
          currency: intl.t('empty-states.system.dummy-data.product-price-2').split(' ')[1],
        },
      },
      {
        title: intl.t('empty-states.system.dummy-data.product-title-3'),
        internalNote: intl.t('empty-states.system.dummy-data.product-internal-note-3'),
        formattedUnit: intl.t('empty-states.system.dummy-data.product-unit-3'),
        unitPrice: {
          value: intl.t('empty-states.system.dummy-data.product-price-3').split(' ')[0],
          currency: intl.t('empty-states.system.dummy-data.product-price-3').split(' ')[1],
        },
      },
      {
        title: intl.t('empty-states.system.dummy-data.product-title-4'),
        internalNote: intl.t('empty-states.system.dummy-data.product-internal-note-4'),
        formattedUnit: intl.t('empty-states.system.dummy-data.product-unit-4'),
        unitPrice: {
          value: intl.t('empty-states.system.dummy-data.product-price-4').split(' ')[0],
          currency: intl.t('empty-states.system.dummy-data.product-price-4').split(' ')[1],
        },
      },
    ],
  };
};
