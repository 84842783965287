import { isTesting, macroCondition } from '@embroider/macros';
import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';
import type IntlService from 'ember-intl/services/intl';

export const AR_ONBOARDING_USER_ACTIONS = {
  RESUME_INVOICE_ONBOARDING: 'resume-invoice-onboarding',
  SETUP_NUMBERING: 'setup-numbering',
  COMPLETE_COMPANY_DETAILS: 'complete-company-details',
  TEMPLATE_CUSTOMIZATION: 'template-customization',
  CLIENTS_IMPORT: 'clients-import',
  ITEMS_IMPORT: 'items-import',
  ACCOUNTANT_INVITE: 'accountant-invite',
  IMPORT_INVOICES: 'import-invoices',
  COMPLETE_COMPANY_PROFILE: 'complete-company-profile',
  CREATE_DEPOSIT_INVOICE: 'deposit-invoice',
  ACTIVATE_PAYMENT_LINKS: 'activate-payment-links',
} as const;

export const SETTINGS_DEFAULT_VALUES = {
  colorText: '#1d1d1b',
  colorTextAlt: '#ffffff',
  colorTheme: '#1d1d1b',
  invoiceHeader:
    'Sehr geehrte Damen und Herren,\nhiermit stellen wir Ihnen folgende Leistungen in Rechnung:',
  invoiceFooter: 'Vielen Dank für die gute Zusammenarbeit.\nMit freundlichen Grüßen',
} as const;

// Tag component has been deprecated, use BadgeStatus with status levels
export const EINVOICING_STATUS_COLORS = {
  approved: 'green',
  not_delivered: 'red',
  pending: 'orange',
  declined: 'red',
  submitted: 'orange',
  submission_failed: 'red',
};

export const EINVOICING_UNIT_CODES = [
  'unit',
  'hour',
  'day',
  'month',
  'gram',
  'kilogram',
  'liter',
  'meter',
  'square_meter',
  'cubic_meter',
  'ampere',
  'number_of_articles',
  'centimeter',
  'cubic_meter_per_hour',
  'gigajoule',
  'gigawatt',
  'gigawatt_hour',
  'half_year',
  'joule',
  'kilojoule',
  'kilovar',
  'kilowatt',
  'kilowatt_hour',
  'megajoule',
  'megawatt',
  'megawatt_hour',
  'milligram',
  'milliliter',
  'millimeter',
  'minute',
  'pair',
  'quarter',
  'second',
  'set',
  'ton',
  'two_week',
  'watt_hour',
  'week',
  'year',
];

// Tag component has been deprecated, use BadgeStatus with INVOICING_STATUS_LEVELS
export const INVOICING_STATUS_COLORS = {
  paid: 'green',
  unpaid: 'purple',
  canceled: 'red',
  draft: 'gray',
};

export const INVOICING_STATUS_LEVELS = {
  paid: 'validated',
  unpaid: 'waiting',
  canceled: 'error',
  draft: 'inactive',
};

export const INVOICED_STATUS = {
  FULLY_INVOICED: 'fully_invoiced',
  PARTIALLY_INVOICED: 'partially_invoiced',
  NO_INVOICES: 'no_invoices',
  CURRENCY_MISMATCH: 'currency_mismatch',
};

export const EINVOICING_STATUS = {
  APPROVED: 'approved',
  NOT_DELIVERED: 'not_delivered',
  PENDING: 'pending',
  REJECTED: 'declined',
  SUBMITTED: 'submitted',
  SUBMISSION_FAILED: 'submission_failed',
};

export const STATUS = {
  DRAFT: 'draft',
  PAID: 'paid',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  COMPLETED: 'paid,canceled',
};

export const SORT_FILTERS = {
  AMOUNT_DUE_CENTS: 'amount_due_cents',
  CREATED_AT: 'created_at',
  CUSTOMER_NAME: 'customers.name',
  DUE_DATE: 'due_date',
  ISSUE_DATE: 'issue_date',
  ISSUE_DATE_OR_IMPORT_ANALYZED_AT: 'issue_date_or_import_analyzed_at',
  NUMBER: 'number',
};

export const DEFAULT_VAT_RATE_PER_COUNTRY = {
  FR: '0.2',
  DE: '0.19',
  ES: '0.21',
  IT: '0.22',
};

export const DEFAULT_LOCALE_PER_COUNTRY = {
  FR: 'fr',
  IT: 'it',
  DE: 'de',
  ES: 'es',
};

export const TERM_AND_CONDITIONS_MAX_LENGTH = 525;
export const TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT = 200;
export const LOCAL_STORAGE_WARNING_DISMISSED_KEY =
  'receivable-invoice-manual-numbering-warning-dismissed';

export const NUMBERING_MODE = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: 'percentage',
  ABSOLUTE: 'absolute',
};
export const DISCOUNT_AMOUNT_LIMIT = 1000000000;
export const IRPF_ES_DEFAULT_RATE = '0.15';

export const SENTRY_IGNORE_HTTP_STATUSES = [404, 409, 422];
export const ERROR_TOAST_IGNORE_HTTP_STATUSES = [409, 422];

/**
 * Timeouts, delays
 */
export const MATCHED_TRANSACTIONS_DELAY = macroCondition(isTesting()) ? 0 : 500;
export const WEBSOCKET_TIMEOUT_MS = macroCondition(isTesting()) ? 600 : 6000;
export const ONBOARDING_WEBSOCKET_TIMEOUT_MS = macroCondition(isTesting()) ? 600 : 10000;
export const ATMA_WEBSOCKET_TIMEOUT_MS = macroCondition(isTesting()) ? 600 : 30000;

export const EXPORT_FORMATS = {
  PDF: 'pdf',
  FATTURAPA: 'fatturapa',
};

export const EXPORT_ERROR_TYPES = {
  LIMIT_REACHED: 'limit_reached',
  NO_INVOICES: 'no_invoices_to_export',
  GENERIC_ERROR: 'generic_error',
};

export const SDD_PAYMENT_METHOD = 'MP19';
export const SDD_PAYMENT_CONDITION = 'TP02';

export const DEFAULT_PAYMENT_METHOD = 'MP05';
export const DEFAULT_PAYMENT_CONDITION = 'TP02';

export const ONBOARDING_EMPTY_STATE_FF = {
  DEFAULT: 'default',
  EDUCATION: 'education',
};

export const VAT_RATE_PRECISION = 0.01;

export const ONBOARDING_STATUS = {
  SKIPPED: 'skipped',
  NOT_ELIGIBLE: 'not_eligible',
  NOT_STARTED: 'not_started',
  STARTED: 'started',
  COMPLETED: 'completed',
};

export const ONBOARDING_STEP_STATUS = {
  TO_DO: 'to_do',
  PREFILLED: 'prefilled',
  CONFIRMED: 'confirmed',
};

export const ONBOARDING_SOURCE = {
  INVOICES: 'invoices',
  RECURRING_INVOICES: 'recurring-invoices',
};

export const ONBOARDING_TRACKING_EVENTS = {
  COMPLETE: 'invoices-onboarding_complete',
  COMPLETE_CONFIRMED: 'invoices-onboarding_complete_confirmed',
  EXIT: 'invoices-onboarding_exit',
  EXIT_CONFIRMED: 'invoices-onboarding_exit_confirmed',
  IMPORT_STARTED: 'invoices-onboarding_import-started',
  SKIP: 'invoices-onboarding_skip',
  SKIP_CONFIRMED: 'invoices-onboarding_skip_confirmed',
  STEP_COMPLETED: 'invoices-onboarding_step-completed',
  STEP_STARTED: 'invoices-onboarding_step-started',
  DISCOUNT_CONDITIONS_EDITED: 'invoices-onboarding_discount-conditions_edited',
  LATE_PAYMENT_PENALTIES_EDITED: 'invoices-onboarding_late-payment-penalties_edited',
  LEGAL_FIXED_COMPENSATION_EDITED: 'invoices-onboarding_legal-fixed-compensation_edited',
  GUIDED_SETUP: 'invoices-onboarding_guided-setup_selected',
  MANUAL_SETUP: 'invoices-onboarding_manual-setup_selected',
};

export const ONBOARDING_OCR_FAILED_EVENT = 'receivable_invoices.onboarding_prefill_failure';
export const ONBOARDING_OCR_SUCCEDED_EVENT = 'receivable_invoices.onboarding_prefill_success';

export const EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY =
  'einvoice-instructional-tooltip-dismissed';

export const PAYMENT_LINKS_SURVEY_PROMO_BOX_KEY = 'payment-links-survey-link-clicked';

export const ES_FREELANCER_LEGAL_FORM = 'Empresarios Individual (Autónomo)';

export const NAVATIC_TOUR_URLS = {
  [LOCALES_DEFINITION.EN.code]: 'https://qonto.navattic.com/yrt07ij',
  [LOCALES_DEFINITION.FR.code]: 'https://qonto.navattic.com/ck1802s8',
  [LOCALES_DEFINITION.IT.code]: 'https://qonto.navattic.com/mj320vln',
  [LOCALES_DEFINITION.ES.code]: 'https://qonto.navattic.com/n9va0dyp',
  [LOCALES_DEFINITION.DE.code]: 'https://qonto.navattic.com/hhuu09v1',
};

export const EINVOICING_LIFECYCLE_EVENT_DESCRIPTION = (
  intl: IntlService,
  statusCode: number
): string | undefined => {
  const descriptions: Record<number, string> = Object.freeze({
    201: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-201'),
    202: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-202'),
    203: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-203'),
    204: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-204'),
    205: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-205'),
    206: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-206'),
    207: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-207'),
    208: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-208'),
    209: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-209'),
    210: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-210'),
    211: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-211'),
    212: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-212'),
    213: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-213'),
    214: intl.t('receivable-invoices.einvoicing-timeline.event-description.event-214'),
  });

  return descriptions[statusCode];
};

export const EINVOICING_LIFECYCLE_EVENT_STATUS_CODES_TO_DISPLAY = [
  201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
];

export const EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES = [206, 207, 208, 214];

export const EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES = [210, 213];

export const PAYMENT_LINK_METHODS = {
  APPLE_PAY: 'apple_pay',
  BANK_TRANSFER: 'bank_transfer',
  CREDIT_CARD: 'credit_card',
  PAYPAL: 'paypal',
} as const;

export type PaymentLinkMethod = (typeof PAYMENT_LINK_METHODS)[keyof typeof PAYMENT_LINK_METHODS];

export const CREATE_DEPOSIT_INVOICE_FROM_QUOTE_DISCOVERABILITY_STORAGE_KEY =
  'create-deposit-invoice-from-quote-discoverability';

export const CREATE_DEPOSIT_INVOICE_FROM_NEW_INVOICE_DISCOVERABILITY_STORAGE_KEY =
  'create-deposit-invoice-from-new-invoice-discoverability';

export const REDIRECT_TO_STEP_ON_ERROR = {
  indexStep: [
    'customer',
    'performance_start_date',
    'performance_end_date',
    'issue_date',
    'due_date',
    'iban',
    'number',
  ],
  productsStep: ['sections', 'discount_amount', 'attachments'],
};
