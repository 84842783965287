import type { StatusType } from '@repo/design-system-kit';

export const FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS = {
  enabled: 'enabled',
  no_qonto: 'no_qonto',
  disabled: 'disabled',
  pending_creation: 'pending_creation',
  pending_deletion: 'pending_deletion',
};

export const FRENCH_EINVOICING_STATUS_TYPE: Record<
  keyof typeof FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS,
  StatusType
> = {
  enabled: 'validated',
  no_qonto: 'error',
  disabled: 'error',
  pending_creation: 'in-progress',
  pending_deletion: 'in-progress',
};
