import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import { CODES, LAYOUT } from 'qonto/constants/empty-states/system';
import { INSURANCE_CONTRACT_STATUS } from 'qonto/constants/insurance-hub';

export const getEmptyStateConfig = (intl, { ctaCallback, legalCountry }) => {
  let dummyData = getDummyData(intl);

  let subtitle;

  switch (legalCountry) {
    case 'IT':
      subtitle = intl.t('insurance-hub.policies.empty-state.a3.it-orga.subtitle');
      break;
    case 'DE':
      subtitle = intl.t('insurance-hub.policies.empty-state.a3.de-orga.subtitle');
      break;
    case 'FR':
    default:
      subtitle = intl.t('insurance-hub.policies.empty-state.a3.fr-orga.subtitle');
      break;
  }

  return {
    name: 'insurance-hub',
    featureName: 'insurance',
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        variations: [
          {
            inputs: {},
            output: {
              title: intl.t('insurance-hub.policies.empty-state.a3.title'),
              subtitle,
              ctaText: intl.t('insurance-hub.policies.empty-state.a3.cta'),
              onClick: ctaCallback,
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  let renewalDate = dayjs().add(1, 'month').format('YYYY-MM-DD');

  return {
    items: [
      {
        name: intl.t('insurance-hub.policies.empty-state.dummy-data.row-1.insurance'),
        contractId: intl.t('insurance-hub.policies.empty-state.dummy-data.row-1.policy-number'),
        price: {
          value: 15,
          currency: 'EUR',
        },
        paymentFrequency: 'month',
        renewalDate,
        status: INSURANCE_CONTRACT_STATUS.ACTIVE,
      },
      {
        name: intl.t('insurance-hub.policies.empty-state.dummy-data.row-2.insurance'),
        contractId: intl.t('insurance-hub.policies.empty-state.dummy-data.row-2.policy-number'),
        price: {
          value: 16,
          currency: 'EUR',
        },
        paymentFrequency: 'month',
        renewalDate,
        status: INSURANCE_CONTRACT_STATUS.ACTIVE,
      },
      {
        name: intl.t('insurance-hub.policies.empty-state.dummy-data.row-3.insurance'),
        contractId: intl.t('insurance-hub.policies.empty-state.dummy-data.row-3.policy-number'),
        price: {
          value: 34,
          currency: 'EUR',
        },
        paymentFrequency: 'month',
        renewalDate,
        status: INSURANCE_CONTRACT_STATUS.PENDING_OTHERS,
      },
      {
        name: intl.t('insurance-hub.policies.empty-state.dummy-data.row-4.insurance'),
        contractId: intl.t('insurance-hub.policies.empty-state.dummy-data.row-4.policy-number'),
        price: {
          value: 12,
          currency: 'EUR',
        },
        paymentFrequency: 'month',
        renewalDate,
        status: INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT,
      },
    ],
  };
};
