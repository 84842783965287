import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import { CODES, LAYOUT } from 'qonto/constants/empty-states/system';

const name = 'card-acquirer-payments';
const featureName = 'tapToPay';

export const getEmptyStateConfig = (intl, openValuePropositionModal = () => {}) => {
  let dummyData = getDummyData(intl);

  return {
    name,
    featureName,
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        badgeType: BADGE_TYPE.ACTIVATE,
        dummyData,
        layout: LAYOUT.DISCOVER_PREVIEW,
        variations: [
          {
            inputs: {
              activateAbilities: ['canReadCardAcquirerPayment'],
            },
            output: {
              ctaText: intl.t('tap-to-pay.payments.empty-state.a3.cta'),
              onClick: openValuePropositionModal,
              subtitle: intl.t('tap-to-pay.payments.empty-state.a3.subtitle'),
              title: intl.t('tap-to-pay.payments.empty-state.a3.title'),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  function createCardAcquirerPayment(
    intl,
    { amountValue, card, daysAgo = 0, isCompleted = false }
  ) {
    return {
      amount: {
        value: amountValue,
        currency: 'EUR',
      },
      avatarInfo: {
        smallLogo: '/icon/card/card-acquirer-payments/money-bag.svg',
      },
      card,
      displayedStatus: {
        text: isCompleted
          ? intl.t('tap-to-pay.payments.status.completed')
          : intl.t('tap-to-pay.payments.status.processing'),
        level: isCompleted ? 'validated' : 'in-progress',
      },
      executionDate: dayjs().subtract(daysAgo, 'day'),
    };
  }

  return {
    items: [
      createCardAcquirerPayment(intl, {
        amountValue: 120,
        card: intl.t('tap-to-pay.payments.empty-state.dummy-data.row-1'),
        daysAgo: 1,
        isCompleted: false,
      }),
      createCardAcquirerPayment(intl, {
        amountValue: 150,
        card: intl.t('tap-to-pay.payments.empty-state.dummy-data.row-2'),
        daysAgo: 2,
        isCompleted: true,
      }),
      createCardAcquirerPayment(intl, {
        amountValue: 82,
        card: intl.t('tap-to-pay.payments.empty-state.dummy-data.row-3'),
        daysAgo: 3,
        isCompleted: true,
      }),
    ],
  };
};
