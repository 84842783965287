export const DIRECT_DEBIT_SUBSCRIPTION_STATUS = {
  PENDING_MANDATE_SIGNATURE: 'pending_mandate_signature',
  SCHEDULED: 'scheduled',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
} as const;

// sorted
export const AVAILABLE_STATUSES = [
  DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS,
] as const;

export const DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS = {
  [DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE]: 'warning',
  [DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED]: 'waiting',
  [DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS]: 'in-progress',
} as const;

export const DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS = {
  [DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE]: 'status_warning',
  [DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED]: 'status_scheduled',
  [DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS]: 'status_processing',
} as const;

export const DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES = {
  ONE_OFF: 'one_off',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
  CUSTOM: 'custom',
} as const;

export const FREQUENCY_CUSTOM_UNITS = {
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years',
} as const;
