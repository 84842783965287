export const PAYMENT_LINK_STATUS = {
  OPEN: 'open',
  PAID: 'paid',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
};

export const PAYMENT_LINK_STATUS_ICONS = {
  [PAYMENT_LINK_STATUS.OPEN]: 'status_scheduled',
  [PAYMENT_LINK_STATUS.PAID]: 'status_approved',
  [PAYMENT_LINK_STATUS.CANCELED]: 'status_cancelled',
  [PAYMENT_LINK_STATUS.EXPIRED]: 'status_cancelled',
};

export const PAYMENT_LINK_BADGE_LEVEL = {
  [PAYMENT_LINK_STATUS.OPEN]: 'waiting',
  [PAYMENT_LINK_STATUS.PAID]: 'validated',
  [PAYMENT_LINK_STATUS.CANCELED]: 'error',
  [PAYMENT_LINK_STATUS.EXPIRED]: 'error',
};

export const PAYMENT_LINK_ELIGIBLE_COUNTRIES = ['FR', 'DE', 'NL'];
