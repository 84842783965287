import { htmlSafe } from '@ember/template';

import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import dayjs from 'dayjs';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';

import { STATUS, TAB } from '../invoice-subscriptions';

const INFORM_LOTTIE_SRC = '/lotties/invoice-subscriptions/inform-empty-state.json';
const EMPTY_RESULTS_LOTTIE_SRC = '/lotties/empty-state/search-no-result.json';

const name = 'recurring-invoices';
const featureName = 'recurringInvoices';

export const getEmptyStateConfig = (
  intl,
  { ctaCallback, currentTab, isSddEligible, showOnboarding }
) => {
  let dummyData = getDummyData(intl, currentTab);

  return {
    name,
    featureName,

    variations: {
      ES_U1: {
        code: CODES.ES_U1,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        badgeType: BADGE_TYPE.UPGRADE,
        badgeText: intl.t('upsell.add-ons.badge'),
        tracking: getTrackingNameAndProperties({
          name,
          type: TYPES.UPSELL,
          customProps: {
            sdd_status: isSddEligible ? 'eligible' : 'not-eligible',
          },
        }),

        variations: [
          {
            inputs: {
              customInputs: {
                isOwnerOrAdmin: true,
              },
            },
            output: {
              title: isSddEligible
                ? intl.t('recurring-invoices.list.empty-state.u1.sdd-eligible.title')
                : intl.t('recurring-invoices.list.empty-state.u1.title'),
              subtitle: isSddEligible
                ? intl.t('recurring-invoices.list.empty-state.u1.sdd-eligible.subtitle')
                : intl.t('recurring-invoices.list.empty-state.u1.subtitle'),
              ctaText: intl.t('upsell.add-ons.cta'),
              onClick: ctaCallback.upsell,
            },
          },
        ],
      },

      ES_T1: {
        code: CODES.ES_T1,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        badgeType: BADGE_TYPE.TRY_FOR_FREE,
        badgeText: intl.t('empty-states.system.try.badge'),
        tracking: getTrackingNameAndProperties({
          name,
          type: TYPES.TRY,
          customProps: {
            sdd_status: isSddEligible ? 'eligible' : 'not-eligible',
          },
        }),

        variations: [
          {
            inputs: {
              customInputs: {
                isOwnerOrAdmin: true,
              },
            },
            output: {
              title: isSddEligible
                ? intl.t('recurring-invoices.list.empty-state.u1.sdd-eligible.title')
                : intl.t('recurring-invoices.list.empty-state.u1.title'),
              subtitle: isSddEligible
                ? intl.t('recurring-invoices.list.empty-state.u1.sdd-eligible.subtitle')
                : intl.t('recurring-invoices.list.empty-state.u1.subtitle'),
              ctaText: intl.t('empty-states.system.try.cta'),
              onClick: ctaCallback.upsell,
            },
          },
        ],
      },

      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        tracking: getTrackingNameAndProperties({
          name,
          type: TYPES.ACTIVATE,
          customProps: {
            sdd_status: isSddEligible ? 'eligible' : 'not-eligible',
          },
        }),
        variations: [
          {
            inputs: {
              customInputs: {
                isOwnerOrAdmin: true,
              },
            },
            output: {
              title: isSddEligible
                ? intl.t('recurring-invoices.list.empty-state.a3.sdd-eligible.title')
                : intl.t('recurring-invoices.list.empty-state.a3.title'),
              subtitle: htmlSafe(
                isSddEligible
                  ? intl.t('recurring-invoices.list.empty-state.a3.sdd-eligible.subtitle')
                  : showOnboarding
                    ? intl.t('recurring-invoices.list.empty-state.a3.onboarding.subtitle')
                    : intl.t('recurring-invoices.list.empty-state.a3.subtitle')
              ),
              ctaText: !showOnboarding && intl.t('recurring-invoices.list.empty-state.a3.cta'),
              onClick: ctaCallback.activate,
            },
          },
        ],
      },

      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        dummyData,
        variations: [
          {
            inputs: {
              customInputs: {
                isOwnerOrAdmin: true,
                tab: TAB.PROCESSING,
              },
            },
            output: {
              title: intl.t('recurring-invoices.list.empty-state.i1.processing.title'),
              subtitle: intl.t('recurring-invoices.list.empty-state.i1.processing.subtitle'),
              lottieSrc: INFORM_LOTTIE_SRC,
              tracking: getTrackingNameAndProperties({
                name,
                type: TYPES.INFORM,
                customProps: { tab: TAB.PROCESSING },
              }),
            },
          },
          {
            inputs: {
              customInputs: {
                isOwnerOrAdmin: true,
                tab: TAB.COMPLETED,
              },
            },
            output: {
              title: intl.t('recurring-invoices.list.empty-state.i1.completed.title'),
              subtitle: intl.t('recurring-invoices.list.empty-state.i1.completed.subtitle'),
              lottieSrc: INFORM_LOTTIE_SRC,
              tracking: getTrackingNameAndProperties({
                name,
                type: TYPES.INFORM,
                customProps: { tab: TAB.COMPLETED },
              }),
            },
          },
        ],
      },

      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        dummyData,
        tracking: getTrackingNameAndProperties({
          name,
          type: TYPES.INFORM,
          customProps: { tab: currentTab },
        }),
        variations: [
          {
            inputs: {
              customInputs: {
                isOwnerOrAdmin: true,
              },
            },
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
              lottieSrc: EMPTY_RESULTS_LOTTIE_SRC,
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = (intl, tab) => {
  let currentDate = dayjs();

  return {
    status: tab === TAB.PROCESSING ? STATUS.ACTIVE : STATUS.FINISHED,
    statsTask: {
      lastSuccessful: {
        value: {
          created: {
            total: 4,
            active: tab === TAB.PROCESSING ? 4 : 0,
            canceled: 0,
            finished: tab === TAB.COMPLETED ? 4 : 0,
            scheduled: 0,
            suspended: 0,
          },
        },
      },
    },
    items: [
      {
        // 1st row
        customerName: intl.t('recurring-invoices.list.empty-state.dummy-data.row-1.client'),
        frequency: {
          value: 1,
          recurrence: 'monthly',
        },
        status: STATUS.SCHEDULED,
        prevInvoiceDate: null,
        nextInvoiceDate: currentDate.add(5, 'day').toISOString(),
        endDate: null,
        currency: 'EUR',
        amountDue: '2812.00',
      },
      {
        // 2nd row
        customerName: intl.t('recurring-invoices.list.empty-state.dummy-data.row-2.client'),
        frequency: {
          value: 1,
          recurrence: 'weekly',
        },
        status: STATUS.ACTIVE,
        prevInvoiceDate: null,
        nextInvoiceDate: currentDate.add(10, 'day').toISOString(),
        endDate: currentDate.add(1, 'year').toISOString(),
        currency: 'EUR',
        amountDue: '2812.00',
      },
      {
        // 3rd row
        customerName: intl.t('recurring-invoices.list.empty-state.dummy-data.row-3.client'),
        frequency: {
          value: 1,
          recurrence: 'quarterly',
        },
        status: STATUS.ACTIVE,
        prevInvoiceDate: null,
        nextInvoiceDate: currentDate.add(7, 'day').toISOString(),
        endDate: currentDate.add(1, 'year').toISOString(),
        currency: 'EUR',
        amountDue: '2812.00',
      },
      {
        // 4th row
        customerName: intl.t('recurring-invoices.list.empty-state.dummy-data.row-4.client'),
        frequency: {
          value: 5,
          recurrence: 'monthly',
        },
        status: STATUS.SUSPENDED,
        prevInvoiceDate: currentDate.subtract(140, 'day').toISOString(),
        nextInvoiceDate: null,
        endDate: currentDate.add(1, 'year').toISOString(),
        currency: 'EUR',
        amountDue: '2812.00',
      },
    ],
  };
};
