export const F24_STATUS = {
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed',
  DECLINED: 'declined',
  CANCELED: 'canceled',
} as const;

export const ERROR_KEYS = {
  compensations_not_allowed: 'f24.manual-declaration.module.errors.field.compensations-not-allowed',
  generic_error: 'f24.manual-declaration.module.errors.field.generic-error',
  invalid_code: 'f24.manual-declaration.module.errors.field.invalid-code',
  invalid_fiscal_code: 'f24.manual-declaration.module.errors.field.invalid-codice-fiscale',
  invalid_end_date: 'f24.manual-declaration.module.errors.field.invalid-end-date',
  invalid_province: 'f24.manual-declaration.module.errors.field.invalid-province',
  invalid_field: 'f24.manual-declaration.module.errors.invalid-field',
  invalid_reference_period: 'f24.manual-declaration.module.errors.field.invalid-reference-period',
  invalid_taxcode: 'f24.manual-declaration.module.errors.field.invalid-taxcode',
  invalid_year: 'f24.manual-declaration.module.errors.field.invalid-year',
  matricola_inps_not_required:
    'f24.manual-declaration.module.errors.field.matricola-inps-not-required',
  field_required: 'f24.manual-declaration.module.errors.field.required',
  uncollectable_tax: 'f24.manual-declaration.module.errors.field.uncollectable-tax',
} as const;
