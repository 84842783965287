import type IntlService from 'ember-intl/services/intl';

export const INTEGRATIONS_PAGE_SIZE = 100;

export const CONNECT_FEATURE_LEVELS = {
  bronze: 'connect_integration_level_bronze',
  silver: 'connect_integration_level_silver',
  gold: 'connect_integration_level_gold',
  accounts_payable: 'connect_integration_ap',
  accounts_receivable: 'connect_integration_ar',
} as const;

export const GMI_SOLUTION_ID_PREFIX = 'cfa1cc4c-f504-48fc-93d1-334d7e100553_';

export const UNDISPLAYED_INTEGRATION = 'undisplayed';

export const UNSORTED_INTEGRATION = 'unsorted';

export const INTEGRATION_TYPES = {
  internal: 'internal_integration',
  partnerships: 'partnerships',
  thirdParty: 'third_party_integration',
} as const;

export const INTEGRATION_SOURCES = {
  MARKETPLACE: 'marketplace',
  ICI: 'in-context',
} as const;

export const CATEGORIES = {
  ACCOUNTING: 'accounting',
  BANKS: 'banks',
  BUSINESS_PAYMENTS: 'business-payments',
  FINANCE: 'finance',
  HR: 'hr',
  CLOUD_STORAGE: 'cloud-storage',
  PRODUCTIVITY: 'productivity',
  INVOICE_IMPORT: 'invoice-import',
  AR_CLIENTS_MIGRATION: 'ar-clients-migration',
};

export const STAKEHOLDERS = {
  EXTERNAL_ACCOUNTANTS: 'external-accountants',
  FINANCING_TEAMS: 'financing-teams',
  FREELANCERS: 'freelancers',
};

export const STAKEHOLDERS_SLUGS = {
  EXTERNAL_ACCOUNTANTS: 'external_accountant',
  FINANCING_TEAMS: 'financing_teams',
  FREELANCERS: 'freelancers',
};

export const ALL_CATEGORIES = (intl: IntlService) => [
  {
    name: intl.t('connect.categories.accounting'),
    slug: CATEGORIES.ACCOUNTING,
    id: CATEGORIES.ACCOUNTING,
  },
  {
    name: intl.t('connect.categories.banks'),
    slug: CATEGORIES.BANKS,
    id: CATEGORIES.BANKS,
  },
  {
    name: intl.t('connect.categories.business-payments'),
    slug: CATEGORIES.BUSINESS_PAYMENTS,
    id: CATEGORIES.BUSINESS_PAYMENTS,
  },
  {
    name: intl.t('connect.categories.finance'),
    slug: CATEGORIES.FINANCE,
    id: CATEGORIES.FINANCE,
  },
  {
    name: intl.t('connect.categories.hr'),
    slug: CATEGORIES.HR,
    id: CATEGORIES.HR,
  },
  {
    name: intl.t('connect.categories.cloud-storage'),
    slug: CATEGORIES.CLOUD_STORAGE,
    id: CATEGORIES.CLOUD_STORAGE,
  },
  {
    name: intl.t('connect.categories.productivity'),
    slug: CATEGORIES.PRODUCTIVITY,
    id: CATEGORIES.PRODUCTIVITY,
  },
  {
    name: intl.t('connect.categories.invoice-import'),
    slug: CATEGORIES.INVOICE_IMPORT,
    id: CATEGORIES.INVOICE_IMPORT,
  },
  {
    name: intl.t('connect.categories.ar-clients-migration'),
    slug: CATEGORIES.AR_CLIENTS_MIGRATION,
    id: CATEGORIES.AR_CLIENTS_MIGRATION,
  },
];

export const ALL_STAKEHOLDERS = (intl: IntlService) => [
  {
    name: intl.t('connect.stakeholders.external-accountants'),
    slug: STAKEHOLDERS_SLUGS.EXTERNAL_ACCOUNTANTS,
    id: STAKEHOLDERS.EXTERNAL_ACCOUNTANTS,
  },
  {
    name: intl.t('connect.stakeholders.financing-teams'),
    slug: STAKEHOLDERS_SLUGS.FINANCING_TEAMS,
    id: STAKEHOLDERS.FINANCING_TEAMS,
  },
  {
    name: intl.t('connect.stakeholders.freelancers'),
    slug: STAKEHOLDERS_SLUGS.FREELANCERS,
    id: STAKEHOLDERS.FREELANCERS,
  },
];

export const COLLECTION_TYPES = {
  INTEGRATIONS: 'integrations',
  PARTNERSHIPS: 'partnerships',
  EBICS: 'ebics',
};

export const COLLECTIONS = [COLLECTION_TYPES.INTEGRATIONS, COLLECTION_TYPES.PARTNERSHIPS];

export const COLLECTIONS_EBICS_ACCESS = [...COLLECTIONS, COLLECTION_TYPES.EBICS];

export const CONNECTION_EXPIRATION_THRESHOLD_IN_DAYS = 14;

export const CONNECTION_EXPIRATION_STATUS = {
  ACTIVE: 'active',
  EXPIRING: 'expiring',
  EXPIRED: 'expired',
  UNKNOWN: 'unknown',
};

export const BANK_CONNECTION_STATUS = {
  SYNC_IN_PROGRESS: 'SYNC_IN_PROGRESS',
  SYNCHRONIZED: 'SYNCHRONIZED',
  SUSPENDED: 'SUSPENDED',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
  PASSWORD_UPDATED: 'PASSWORD_UPDATED',
  ACTION_REQUIRED_ON_BANK: 'ACTION_REQUIRED_ON_BANK',
  PROVIDER_ISSUE: 'PROVIDER_ISSUE',
};

export const BANK_CONNECTION_ACTIONABLE_STATUSES = [
  BANK_CONNECTION_STATUS.SUSPENDED,
  BANK_CONNECTION_STATUS.PASSWORD_EXPIRED,
  BANK_CONNECTION_STATUS.PASSWORD_UPDATED,
  BANK_CONNECTION_STATUS.ACTION_REQUIRED_ON_BANK,
];

export const BANK_CONNECTION_FAILURE_STATUSES = [
  ...BANK_CONNECTION_ACTIONABLE_STATUSES,
  BANK_CONNECTION_STATUS.PROVIDER_ISSUE,
];

export const BANK_CONNECTION_PROVIDER = {
  POWENS: 'POWENS',
  FINAPI: 'FINAPI',
};

export const BANK_CONNECTION_OUTCOME = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const BANK_CONNECTIONS_PAGE_SIZE = 999;

export const SOLUTION_INSTANCE_TYPES = {
  GMI: 'gmi',
};

export const SOLUTION_INSTANCE_STATUSES = {
  ENABLED: 'enabled',
};

export const BUTTON_TYPES = {
  QONTO_INTELLIGENCE: 'qonto_intelligence',
};
