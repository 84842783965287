const SEGMENTATION_LEVELS = {
  ap_ar: {
    title: `einvoicing-consent.ap-ar.title`,
    subtitle: `einvoicing-consent.ap-ar.subtitle`,
  },
  third_party: {
    title: `einvoicing-consent.third-party.title`,
    subtitle: `einvoicing-consent.third-party.subtitle`,
  },
  standard: {
    title: `einvoicing-consent.standard.title`,
    subtitle: `einvoicing-consent.standard.subtitle`,
  },
} as const;

const LAST_DISMISSED_DATE_LOCAL_STORAGE_KEY = 'e-invoicing-last-dismissed-date';

export { SEGMENTATION_LEVELS, LAST_DISMISSED_DATE_LOCAL_STORAGE_KEY };
