const COMPONENT_NAMESPACE = 'transfers/international-out/dynamic-form';
const FIELD_COMPONENTS_NAMESPACE = `${COMPONENT_NAMESPACE}/field`;

const TRIGGER_SELECT_COMPONENTS = {
  COUNTRY: `${FIELD_COMPONENTS_NAMESPACE}/select/country-trigger`,
  DEFAULT: `${FIELD_COMPONENTS_NAMESPACE}/select/default-trigger`,
} as const;

const ALLOWED_FIELD_TYPES = {
  DATE: 'date',
  RADIO: 'radio',
  SELECT: 'select',
  TEXT: 'text',
} as const;

const VALIDATION_TRIGGER_TYPES = {
  BLUR: 'blur',
  INPUT: 'input',
} as const;

export {
  ALLOWED_FIELD_TYPES,
  COMPONENT_NAMESPACE,
  FIELD_COMPONENTS_NAMESPACE,
  TRIGGER_SELECT_COMPONENTS,
  VALIDATION_TRIGGER_TYPES,
};
