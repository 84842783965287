export const HOTKEYS = {
  K: 'quick_actions',
  U: 'supplier_invoices',
  I: 'prompt',
};
export const QUICK_ACTIONS_DISCOVERABILITY_STORAGE_KEY = 'quick-actions-discoverability-viewed';

export function modifierKey(isMac) {
  if (isMac) {
    return '⌘';
  }

  return 'Ctrl';
}

export function initializeQuickActions(owner) {
  let router = owner.lookup('service:router');
  let flowLinkManager = owner.lookup('service:flow-link-manager');
  let intl = owner.lookup('service:intl');
  let qontoPilotService = owner.lookup('service:qonto-pilot-service');
  let organizationManager = owner.lookup('service:organization-manager');
  let toastFlashMessages = owner.lookup('service:toast-flash-messages');
  let abilities = owner.lookup('service:abilities');

  // Map an action group string identifier, to its action group object.
  //
  // The action group has the following structure:
  // - name: The name of the action group.
  // - hideHeader: A boolean to hide the header of the group in the UI.
  // - actions: An array of action objects.
  //
  // The action object has the following structure:
  // - key: A unique identifier for the action.
  // - isEnabled: A boolean indicating whether the action should be shown.
  // - isBeta: A boolean to show a beta label on the action.
  // - copy: The i18n text to display for the action.
  // - hotkey: The hotkey to trigger the action.
  // - illustration: The icon to display on the left of the action entry.
  // - action: The function to call when the action is triggered.
  // - isFocused: A boolean for whether the action is selected.
  // - storeObject: The store object that the action is associated with,
  //   in particular, for searching through Qonto objects.
  // - searchText: Fuzzy searching will match within this text.
  let quickActions = {
    [intl.t('qonto-pilot.modal.quick-action.group.recent')]: {
      name: 'recent',
      hideHeader: false,
      actions: [],
    },
    [intl.t('qonto-pilot.modal.quick-action.group.tasks')]: {
      name: 'tasks',
      hideHeader: false,
      actions: [
        {
          key: 'task-sepa',
          isEnabled: true,
          isBeta: true,
          copy: intl.t('qonto-pilot.modal.quick-action.sepa'),
          searchText: intl.t('qonto-pilot.modal.quick-action.sepa'),
          hotkey: 'I',
          illustration: 'icon_nav_transfer',
          action: () => {},
        },
        {
          key: 'task-receipt',
          isEnabled: true,
          hotkey: 'U',
          copy: intl.t('qonto-pilot.modal.quick-action.receipt'),
          searchText: intl.t('qonto-pilot.modal.quick-action.receipt'),
          illustration: 'icon_upload_outlined',
          action: () => {},
        },
      ],
    },
    [intl.t('qonto-pilot.modal.quick-action.group.shortcuts')]: {
      name: 'shortcuts',
      hideHeader: false,
      actions: [
        {
          key: 'shortcut-transfer',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.make-transfer'),
          searchText: intl.t('qonto-pilot.modal.quick-action.make-transfer'),
          illustration: 'icon_shortcut_create_transfer',
          action: () => {
            router.transitionTo('transfers.landing');
          },
        },
        {
          key: 'shortcut-card',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.card'),
          searchText: intl.t('qonto-pilot.modal.quick-action.card'),
          illustration: 'icon_shortcut_create_card',
          action: () => {
            router.transitionTo('cards.physical-or-virtual');
          },
        },
        {
          key: 'shortcut-download-statements',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.account-statements'),
          searchText: intl.t('qonto-pilot.modal.quick-action.account-statements'),
          illustration: 'icon_download_outlined',
          action: () => {
            router.transitionTo('accounts.statements');
          },
        },
        {
          key: 'shortcut-direct-debit',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.direct-debit'),
          searchText: intl.t('qonto-pilot.modal.quick-action.direct-debit'),
          illustration: 'icon_nav_transaction_unselected',
          action: () => {
            flowLinkManager.transitionTo({ name: 'mandate', stepId: 'landing' });
          },
        },
        {
          key: 'shortcut-add-account',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.account'),
          searchText: intl.t('qonto-pilot.modal.quick-action.account'),
          illustration: 'icon_shortcut_add_external_account',
          action: () => {
            router.transitionTo('accounts.new');
          },
        },
        {
          key: 'shortcut-setup-integration',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.integration'),
          searchText: intl.t('qonto-pilot.modal.quick-action.integration'),
          illustration: 'icon_shortcut_integration',
          action: () => {
            router.transitionTo('settings.connect-hub.collections', {
              collection_slug: 'integrations',
            });
          },
        },
        {
          key: 'shortcut-invite-employee',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.member'),
          searchText: intl.t('qonto-pilot.modal.quick-action.member'),
          illustration: 'icon_user_plus_outlined',
          action: () => {
            let { kycSubmitted, kycAccepted, shouldSubmitKyc } = organizationManager.membership;
            let kycUnknown = kycSubmitted && !kycAccepted;

            if (shouldSubmitKyc) {
              return router.transitionTo('kyc.intro', {
                queryParams: {
                  redirectRoute: 'members.active',
                },
              });
            }

            if (kycUnknown) {
              return toastFlashMessages.toastError(
                intl.t('user-access.kyc.error.pending-rejected-unknown')
              );
            }

            flowLinkManager.transitionTo({
              name: 'member-invite',
              stepId: 'role',
            });
          },
        },
        {
          key: 'shortcut-expense-reimbursement',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.reimbursement'),
          searchText: intl.t('qonto-pilot.modal.quick-action.reimbursement'),
          illustration: 'icon_shortcut_reimbursed',
          action: () => {
            if (abilities.can('create expense report request')) {
              router.transitionTo('requests.landing', {
                queryParams: {
                  // This parameter ensures redirection to reimbursements page if the user exits the landing page
                  origin: 'reimbursements',
                },
              });
            } else {
              // Redirects to the reimbursements page for upsell users without the expense report permissions
              router.transitionTo('reimbursements');
            }
          },
        },
        {
          key: 'shortcut-view-accounts',
          isEnabled: true,
          copy: intl.t('qonto-pilot.modal.quick-action.iban'),
          searchText: [
            intl.t('qonto-pilot.modal.quick-action.iban'),
            'iban',
            'rib',
            'bank details',
          ].join('\u001E'),
          illustration: 'icon_iban',
          action: () => {
            router.transitionTo('accounts.index');
          },
        },
      ],
    },
  };

  let recentKeys = qontoPilotService.getRecentQuickActions();
  let allActions = [
    ...quickActions[intl.t('qonto-pilot.modal.quick-action.group.tasks')].actions,
    ...quickActions[intl.t('qonto-pilot.modal.quick-action.group.shortcuts')].actions,
  ];

  quickActions[intl.t('qonto-pilot.modal.quick-action.group.recent')].actions = recentKeys
    .map(key => allActions.find(action => action.key === key))
    .filter(Boolean);

  return quickActions;
}

/**
 * To avoid tests having to follow these timings, delays are exported as objects so that their value can be changed in tests
 */
export const QONTO_PILOT_DELAYS = {
  supplierInvoices: 7000,
  searchInputDebounce: 500,
};
