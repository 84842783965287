import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { TABS } from 'qonto/constants/supplier-invoice';

const name = 'supplier-invoices';
const featureName = 'supplierInvoices';
const EMPTY_LOTTIE_SRC = '/lotties/empty-state/no-supplier-invoices.json';
const EMPTY_LOTTIE_PENDING_SRC = '/lotties/empty-state/no-tasks.json';
const EMPTY_LOTTIE_NO_RESULTS_SRC = '/lotties/empty-state/search-no-result.json';

export const getEmptyStateConfig = intl => {
  return {
    name,
    featureName,
    variations: {
      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            inputs: {
              customInputs: {
                tab: TABS.ALL_INVOICES,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.all-invoices.empty-state.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.all-invoices.empty-state.body'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.TO_PAY,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.to-pay.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.to-pay.i1.subtitle'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.INBOX,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.inbox.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.inbox.i1.subtitle'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.TO_APPROVE,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.to-approve.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.to-approve.i1.subtitle'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.SCHEDULED,
              },
              copyAbilities: ['canUpdateSupplierInvoices'],
            },
            output: {
              title: intl.t('supplier-invoices.scheduled.empty.title'),
              subtitle: intl.t('supplier-invoices.scheduled.empty.subtitle'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.SCHEDULED,
              },
            },
            output: {
              title: intl.t('supplier-invoices.scheduled.empty.title'),
              subtitle: intl.t('supplier-invoices.scheduled.empty.subtitle'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.TASKS,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.to-approve.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.to-approve.i1.body'),
              lottieSrc: EMPTY_LOTTIE_PENDING_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: 'requests',
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.pending-requests.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.pending-requests.i1.body'),
              lottieSrc: EMPTY_LOTTIE_PENDING_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.COMPLETED,
              },
              copyAbilities: ['canUpdateSupplierInvoices'],
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.completed.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.completed.i1.body-authorised'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.COMPLETED,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.completed.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.completed.i1.body'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.COMPLETED,
              },
              copyAbilities: ['canUpdateSupplierInvoices'],
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.completed.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.completed.i1.body-authorised'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.COMPLETED,
              },
            },
            output: {
              title: intl.t('supplier-invoices.section.tabs.completed.i1.title'),
              subtitle: intl.t('supplier-invoices.section.tabs.completed.i1.body'),
              lottieSrc: EMPTY_LOTTIE_SRC,
            },
          },
        ],
      },
      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_LOTTIE_NO_RESULTS_SRC,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },
    },
  };
};
