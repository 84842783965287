export const QUOTE_STATUSES = {
  APPROVED: 'approved',
  CANCELED: 'canceled',
  PENDING: 'pending_approval',
  COMPLETED: 'approved,canceled',
};

// Tag component has been deprecated, use BadgeStatus with QUOTE_STATUS_LEVELS
export const QUOTE_STATUS_COLORS = {
  pending_approval: 'orange',
  approved: 'green',
  canceled: 'red',
};

export const QUOTE_STATUS_LEVELS = {
  pending_approval: 'in-progress',
  approved: 'validated',
  canceled: 'error',
};

export const QUOTE_ESIGNATURE_LIFECYCLE_EVENT_STATUS = {
  CANCELED: 'canceled',
  WAITING: 'waiting',
  EXPIRED: 'expired',
  OPENED: 'opened',
  SENT: 'sent',
  SIGNED_BY_QONTO_USER: 'signed_by_qonto_user',
  SIGNED_BY_CLIENT: 'signed_by_client',
};
