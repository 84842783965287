export const PAYMENT_METHODS = {
  ACH: 'aba',
  SWIFT: 'swift_code',
  WIRE: 'fedwire_local',
};

export const AUTHORIZED_USD_PAYMENT_METHODS = {
  ACH: PAYMENT_METHODS.ACH,
  SWIFT: PAYMENT_METHODS.SWIFT,
};
