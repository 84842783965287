export const LIMIT_TYPES = {
  MONTHLY: 'monthly',
  PER_TRANSFER: 'per_transfer',
} as const;

export const WARNINGS = {
  ALREADY_ISSUED: 'already_issued',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  INSUFFICIENT_PER_TRANSFER_LIMIT: 'insufficient_per_transfer_limits',
  INSUFFICIENT_MONTHLY_LIMIT: 'insufficient_monthly_limits',
} as const;
