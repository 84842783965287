export const FILE_TYPES = {
  CSV: 'CSV',
  XLS: 'XLS',
  QIF: 'QIF',
  OFX: 'OFX',
  MT940: 'MT940',
  TEAMSYSTEM: 'TEAMSYSTEM',
};

export const FILE_GROUPS = [
  {
    // for TEAMSYSTEM enabled export modals, both excel options should export xls_teamsystem
    name: 'transactions.modals.export.file_formats.excel',
    code: 'xls',
    group: FILE_TYPES.XLS,
    disabled: false,
  },
  {
    name: 'transactions.modals.export.file_formats.csv',
    code: 'csv',
    group: FILE_TYPES.CSV,
    disabled: false,
  },
];

export const MAX_CUSTOM_TEMPLATES = 100;
