export const DEFAULT_SORT_ORDER = 'asc' as const;

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export const COUNTERPARTIES_SORT_BYS = {
  NAME: 'name',
  VAT: 'vat_rate',
  TRANSACTIONS_COUNT: 'transfer_transactions_count',
} as const;

export const F24_SORT_BYS = {
  NAME: 'name',
  SUBMISSION_DATE: 'created_at',
  EXECUTION_DATE: 'execution_date',
  AMOUNT: 'document_amount',
} as const;

export const F24_V2_SORT_BYS = {
  NAME: 'name',
  EXPIRATION_DATE: 'expiration_date',
  AMOUNT: 'document_amount',
} as const;

export const NRC_PAYMENTS_SORT_BYS = {
  TAX_CODE: 'tax_code',
  EXECUTION_DATE: 'execution_date',
  DUE_DATE: 'due_date',
  AMOUNT: 'amount',
} as const;

export const RIBA_SORT_BYS = {
  LAST_CHANGED_ON: 'last_changed_on',
  CREDITOR_NAME: 'creditor_name',
  EXECUTION_DATE: 'execution_date',
  AMOUNT: 'amount',
} as const;

export type AriaSort = 'none' | 'ascending' | 'descending';
export type SortOrder = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];
