import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { STATUS } from 'qonto/constants/transactions';

const EMPTY_RESULTS = '/lotties/empty-state/search-no-result.json';
const NO_TRANSACTIONS = '/lotties/empty-state/no-transaction.json';

const name = 'transactions';

export const getEmptyStateConfig = intl => {
  let dummyData = getDummyData(intl);
  return {
    name,
    featureName: name,
    variations: {
      ES_A3: {
        code: 'ES_A3',
        layout: LAYOUT.DISCOVER_PREVIEW,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        dummyData,
        variations: [
          // I3 Variations
          {
            inputs: {
              activateAbilities: [`canImportExternalBankAccount`],
            },
            output: {
              tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
              title: intl.t('transactions.empty-state.a3.bankless.title'),
              subtitle: intl.t('transactions.empty-state.a3.bankless.subtitle'),
              cta: intl.t('transactions.empty-state.a3.bankless.cta'),
            },
          },
          {
            inputs: {
              activateAbilities: [`canAccessBankAccount`],
            },
            output: {
              title: intl.t('transactions.empty-state.a3.top-up.title'),
              subtitle: intl.t('transactions.empty-state.a3.top-up.subtitle'),
            },
          },
          {
            inputs: {
              copyAbilities: ['canQualifyForAccounting'],
            },
            output: {
              code: CODES.ES_I3,
              tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
              title: intl.t('transactions.empty-state.i3.title'),
              subtitle: intl.t('transactions.empty-state.i3.bookkeeping.subtitle'),
            },
          },
          {
            inputs: {
              activateAbilities: [`canCreateInvite`],
            },
            output: {
              title: intl.t('transactions.empty-state.a3.invite.title'),
              subtitle: intl.t('transactions.empty-state.a3.invite.subtitle'),
            },
          },
          {
            output: {
              code: CODES.ES_I3,
              tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
              title: intl.t('transactions.empty-state.i3.title'),
              subtitle: intl.t('transactions.empty-state.i3.no-bookkeeping.subtitle'),
            },
          },
        ],
      },
      ES_I3: {
        code: 'ES_I3',
        layout: LAYOUT.DISCOVER_PREVIEW,
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        dummyData,
        variations: [
          {
            output: {
              tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
              title: intl.t('transactions.empty-state.i3.title'),
              subtitle: intl.t('transactions.empty-state.i3.no-bookkeeping.subtitle'),
            },
          },
        ],
      },
      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        lottieSrc: NO_TRANSACTIONS,
        variations: [
          {
            inputs: {
              copyAbilities: ['canReadBankAccount'],
              customInputs: {
                isBankAccountClosed: true,
                hasMultiAccountsFeature: true,
              },
            },
            output: {
              title: intl.t('transactions.empty-state.i1.closed-account.title'),
              subtitle: intl.t('transactions.empty-state.i1.closed-account.subtitle'),
            },
          },
          {
            output: {
              title: intl.t('transactions.empty-state.i1.account-selected.title'),
              subtitle: intl.t('transactions.empty-state.i1.account-selected.subtitle'),
            },
          },
        ],
      },
      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_RESULTS,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  let emittedAt = new Date();
  return {
    items: [
      {
        methodLabel: { method: intl.t('transactions.operation-types.card') },
        avatarInfo: {
          smallLogo: '/illustrations/dummy-transactions/dhl.png',
          icon: 'status_processing',
        },
        counterpartyName: intl.t('transactions.empty-state.dummy-data.row-1.merchant'),
        emittedAt,
        attachmentCount: 1,
        attachmentRequired: true,
        status: STATUS.PENDING,
        completed: false,
        signedAmount: '-100.5',
        amountCurrency: 'EUR',
        chargebackDisputingTypes: [],
      },
      {
        methodLabel: { method: intl.t('transactions.operation-types.card') },
        avatarInfo: { smallLogo: '/illustrations/dummy-transactions/amazon.png' },
        counterpartyName: intl.t('transactions.empty-state.dummy-data.row-2.merchant'),
        emittedAt,
        attachmentCount: 1,
        attachmentRequired: true,
        status: STATUS.COMPLETED,
        completed: true,
        signedAmount: '-100.5',
        amountCurrency: 'EUR',
        chargebackDisputingTypes: [],
      },
      {
        methodLabel: { method: intl.t('transactions.operation-types.transfer') },
        avatarInfo: { smallLogo: '/illustrations/dummy-transactions/apple.png' },
        counterpartyName: intl.t('transactions.empty-state.dummy-data.row-3.merchant'),
        emittedAt,
        attachmentCount: 1,
        attachmentRequired: true,
        status: STATUS.COMPLETED,
        completed: true,
        signedAmount: '-100.5',
        amountCurrency: 'EUR',
        chargebackDisputingTypes: [],
      },
      {
        methodLabel: { method: intl.t('transactions.operation-types.transfer') },
        avatarInfo: { smallLogo: '/illustrations/dummy-transactions/qonto.png' },
        counterpartyName: intl.t('transactions.empty-state.dummy-data.row-4.merchant'),
        emittedAt,
        attachmentCount: 1,
        attachmentRequired: true,
        status: STATUS.COMPLETED,
        completed: true,
        signedAmount: '-100.5',
        amountCurrency: 'EUR',
        chargebackDisputingTypes: [],
      },
    ],
  };
};
