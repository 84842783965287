import { StandingFrequency } from 'qonto/services/international-out/types';

export const STANDING_FREQUENCIES: Record<StandingFrequency, string> = {
  [StandingFrequency.None]: 'none',
  [StandingFrequency.Weekly]: 'weekly',
  [StandingFrequency.Monthly]: 'monthly',
  [StandingFrequency.Quarterly]: 'quarterly',
  [StandingFrequency.HalfYearly]: 'half-yearly',
  [StandingFrequency.Yearly]: 'yearly',
} as const;
