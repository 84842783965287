import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';

export const NAVATIC_EMPLOYEE_TOUR_URLS = {
  [LOCALES_DEFINITION.EN.code]: 'https://qonto.navattic.com/i370m89',
  [LOCALES_DEFINITION.FR.code]: 'https://qonto.navattic.com/851l0y09',
  [LOCALES_DEFINITION.IT.code]: 'https://qonto.navattic.com/491h0hfd',
  [LOCALES_DEFINITION.ES.code]: 'https://qonto.navattic.com/rl1h0nlb',
  [LOCALES_DEFINITION.DE.code]: 'https://qonto.navattic.com/ru1h0zmk',
  [LOCALES_DEFINITION.PT.code]: 'https://qonto.navattic.com/i71i0e0g',
};
