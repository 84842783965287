import { variation } from 'ember-launch-darkly';

export const INSURANCE_HUB_LANDING_PAGE = {
  INSURANCES: 'insurances',
  POLICIES: 'policies',
};

export const ENABLED_LEGAL_COUNTRIES = ['FR', 'DE'];

export const getEnabledLegalCountries = () => {
  return [
    ...ENABLED_LEGAL_COUNTRIES,
    ...(variation('feature--boolean-insurance-hub-it') ? ['IT'] : []),
  ];
};

export const PROVIDERS_MAP = {
  DE: {
    faqKey: 'insurance-hub-surein',
    providerName: 'SureIn',
    providerLogos: [{ logo: '/illustrations/insurance-hub/surein.svg', name: 'SureIn' }],
    legalArticle: {
      de: '#erstinformation-de',
      en: '#erstinformation-en',
    },
  },
  FR: {
    faqKey: 'insurance-hub-stello',
    providerName: 'Stello',
    providerLogos: [
      { logo: '/illustrations/insurance-hub/axa.svg', name: 'Axa' },
      { logo: '/illustrations/insurance-hub/stello.svg', name: 'Stello' },
    ],
    legalArticle: {
      fr: '#information-prealable-a-la-conclusion-d-un-contrat-d-assurance-fr',
      en: '#pre-contractual-information-for-the-conclusion-of-an-insurance-contract-en',
    },
  },
  IT: {
    faqKey: 'insurance-hub-facile',
    providerName: 'Finital',
    providerLogos: [{ logo: '/illustrations/insurance-hub/facile.svg', name: 'Facile' }],
    legalArticle: {
      it: '#information-prealable-a-la-conclusion-d-un-contrat-d-assurance-fr',
      en: '#pre-contractual-information-for-the-conclusion-of-an-insurance-contract-en',
    },
  },
};

export const CATEGORIES = {
  BUSINESSES: 'businesses',
  EMPLOYEES: 'employees',
  OWNERS: 'owners',
};

export const PRODUCT_ROUTE = 'insurance-hub.insurances.product';
export const INDEX_ROUTE = 'insurance-hub.insurances.index';

export const INSURANCE_CONTRACT_STATUS = {
  ACTION_REQUIRED: 'action_required',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  PENDING_OTHERS: 'pending_others',
  PENDING_PAYMENT: 'pending_payment',
};

export const INSURANCE_CONTRACT_INTERNAL_STATUS = {
  ACTION_REQUIRED: 'action_required',
  ACTIVE: 'active',
  SCHEDULED: 'scheduled',
  EXPIRED: 'expired',
};

export const INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER = [
  INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  INSURANCE_CONTRACT_INTERNAL_STATUS.ACTIVE,
  INSURANCE_CONTRACT_INTERNAL_STATUS.SCHEDULED,
  INSURANCE_CONTRACT_INTERNAL_STATUS.EXPIRED,
];

export const INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING = {
  [INSURANCE_CONTRACT_STATUS.ACTION_REQUIRED]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  [INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  [INSURANCE_CONTRACT_STATUS.ACTIVE]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTIVE,
  [INSURANCE_CONTRACT_STATUS.PENDING_OTHERS]: INSURANCE_CONTRACT_INTERNAL_STATUS.SCHEDULED,
  [INSURANCE_CONTRACT_STATUS.EXPIRED]: INSURANCE_CONTRACT_INTERNAL_STATUS.EXPIRED,
};

export const INSURANCE_CONTRACT_FREQUENCY = {
  MONTHLY: 'month',
  QUARTERLY: 'quarter',
  YEARLY: 'annual',
  SEMESTER: 'semester',
};

export const INSURANCE_PRODUCT_TYPES = {
  // Stello (FR)
  BUSINESS_LIABILITY: 'business_liability',
  COLLECTIVE_HEALTCARE: 'collective_healthcare',
  CYBER: 'cyber',
  DECENNIAL: 'decennial',
  HEALTHCARE: 'healthcare',
  IT_HARDWARE_DAMAGE: 'it_hardware_damage',
  KEY_PERSON: 'key_person',
  LEGAL_PROTECTION: 'legal_protection',
  LEGAL_SERVICE: 'legal_service',
  MOTOR_FLEET: 'motor_fleet',
  MULTI_RISK: 'multi_risk',
  OFFICE: 'office',
  PUBLIC_LIABILITY: 'public_liability',
  REVENUE_LOSS: 'revenue_loss',

  // Surein (DE)
  BUSINESS_LIABILITY_SUREIN: 'business_liability_surein',
  CYBER_SUREIN: 'cyber_surein',
  FINANCIAL_LOSS_PROTECTION_SUREIN: 'financial_loss_protection_surein',
  LEGAL_PROTECTION_SUREIN: 'legal_protection_surein',

  // Facile (IT)
  PERSONAL_PROTECTION: 'personal-protection-29risk',
  MULTI_RISK_FOR_BUSINESS: 'multi-risk-business-29risk',
  MULTI_RISK_FOR_CORPORATE_ENTITIES: 'multi-risk-corporate-entities-29risk',
  MULTI_RISK_FOR_PROFESSIONAL: 'multi-risk-for-professional-29risk',
  CATASTROPHIC_DAMAGE: 'catastrophic-damage-29risk',
  INJURIES: 'injuries-29risk',
  TERM_LIFE: 'term-life-29risk',
};

// Facile (IT)
export const FACILE_INSURANCE_PRODUCT_LEGAL_TYPES = {
  MULTI_RISK_FOR_PROFESSIONAL: 'multi-risk-for-professional',
  INJURIES: 'injuries-insurance',
  TERM_LIFE: 'term-life-insurance',
};

export const FACILE_INSURANCE_PRODUCT_LEGAL_TYPES_SUPERSCRIPT = {
  [FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.MULTI_RISK_FOR_PROFESSIONAL]: 1,
  [FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.INJURIES]: 2,
};
