import { CODES, LAYOUT } from 'qonto/constants/empty-states/system';

const UPCOMING_TRANSACTIONS_SRC = '/lotties/upcoming-transactions/upcoming-transactions.json';

export const getEmptyStateConfig = intl => {
  return {
    name: 'upcoming-transactions',
    featureName: 'transactions',
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.INFORM,
        variations: [
          {
            inputs: {
              canView: ['canViewUpcomingTransactions'],
            },
            output: {
              title: intl.t('upcoming-transactions.empty-state.no-transactions.title'),
              subtitle: intl.t('upcoming-transactions.empty-state.no-transactions.subtitle'),
              lottieSrc: UPCOMING_TRANSACTIONS_SRC,
            },
          },
        ],
      },
    },
  };
};
